import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWidget from '../../containers/formField/LoadingWidget';
import { getVentureCapitalDealsReducer, getVentureCapitalDealsWithVCIDReducer } from '../../reducers/TrialsReducer';
import { useLocation, useParams } from 'react-router-dom';
import Header from './Header';
import HeaderPage from './HeaderPage';
import GeneralTable from './GeneralTable';
import { ButtonGroup, Button } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { GetPlayersAllDispatchLevelLeaf } from '../../reducers/DiseasesReducer';
import StaticLoadingWidget from '../../containers/formField/StaticLoadingWidget';
import AccordionSearchBar from './reusableComponents/AccordionSearchBar';
import InvestmentsGrid from './InvestmentsGrid';
import { exactSearch } from './Helper';

const Investments = ({hideHeader, search_vc, vc_investment_options=''}) => {
  const trials = useSelector((state) => state.trials);
  let { dataArrangementVentureCapitalDeals, dataArrangementVentureCapitalDealsWithVCID, getCount } = trials
  let { page, name, id } = useParams();
  const [brands, setBrands] = useState({});
  const [searchVal, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [levelLeaf, setlevelLeaf] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let search = params.get('search')?params.get('search'): (search_vc ? search_vc : '');
  let [limit, setLimit] = useState(hideHeader==true?50:100);
  let [offset, setOffset] = useState(0);
  const [dataview, setDataview] = useState(false);
  name = name?name:''
  let searchData = exactSearch(search)
  let vc_investment_page = vc_investment_options && vc_investment_options['company_status'] ? vc_investment_options['company_status'] : ''
  let vc_id = vc_investment_options && vc_investment_options['vc_id'] ? vc_investment_options['vc_id'] : ''
  // console.log(vc_investment_options,'YAYAYAY')
  const options = {
    leafModalData:{},
    trialType:'investments',
    staticLoading:trials.staticLoading,
    columns:vc_id?{'axl_company_vc':'','round':'','raised':'','total_raised':'total','axl_company_description_extract_from_pressrelease':'description'}:{'axl_company_vc':'','investors':'','round':'','raised':'','total_raised':'total','axl_company_description_extract_from_pressrelease':'description'},
    showChips:true,
    // Key is the Location of Chip. Value is the which column show in the chips.
    chipColumn:{'round':'round', 'axl_company_vc':'axl_company_type'},
  }

  const apiCallRequestTable = (data) => {
    setCompany(data)
  }

  if(company){
    options.leafModalData = {
      name:company['axl_company_vc'],
      vc:company,
      nextData:[]
    }
  }

  const findTrials = (val) => {
    let searchData = exactSearch(val)
    let searchItems = {
      limit: limit,
      offset: 0,
      level:'level1',
      search:searchData['search'],
      search_type:searchData['search_type'],
      search_query_sting:name?name:'',
      vc_investment_page: vc_investment_page
    }
    setSearch(searchData['search'])
    dispatch(getVentureCapitalDealsReducer(searchItems));
  };

  let searchItems = {
    limit: limit,
    offset: offset,
    level:'level1',
    search:searchData['search'],
    search_type:searchData['search_type'],
    search_query_sting:name?name:'',
    vc_investment_page: vc_investment_page,
    vc_id:vc_id
  }

  useEffect(() => {
    if(search){
      setSearch(searchData['search'])
    }
    if(vc_id){
      dispatch(getVentureCapitalDealsWithVCIDReducer(searchItems));
    }else{
      dispatch(getVentureCapitalDealsReducer(searchItems));
    }
  }, [dispatch,offset,vc_id]);

  let loadMoreRequest = () => {
    setOffset(offset+limit)
  }

  const handleView = (v) =>{
    setDataview(v)
    setOffset(0)
  }

  const apiCallRequest = (val, offset, level, apiRun, parent, sub_patent) => {
    let searchItems = {}
    if(level == 'level2'){
      setLevel2(val)
      searchItems = {
        filter_by : val,
        level:'level2',
        search:searchVal,
        search_type:searchData['search_type'],
        is_frontend:true,
        limit: limit,
        offset: offset,
        is_view : dataview?'true':'false',
        vc_investment_page: vc_investment_page
      }
    }
    if(level == 'levelLeaf'){
      setlevelLeaf(val)
      searchItems = {
        filter_by : sub_patent?sub_patent:'Other',
        filter_by_2 : parent,
        filter_by_3 : val,
        is_leaf : true,
        search:searchVal,
        search_type:searchData['search_type'],
        is_frontend:true,
        is_view : dataview?'true':'false',
        vc_investment_page: vc_investment_page
      }
    }
    if(apiRun == 'no'){
      // dispatch(getDealsAllDispatch(searchItems));
    }
    if(apiRun == 'leaf_no'){
      // dispatch(getDealsDispatchLevelLeaf(searchItems));
    }
    options.leafModalData = {}
  }

  if(vc_investment_options && vc_investment_options['company_status'] == 'Active'){
    dataArrangementVentureCapitalDeals = dataArrangementVentureCapitalDeals.filter((item) => item.axl_company_status === 'Active');
  }

  const excludeDealTypes = ['Acquisition', 'Merger', 'IPO', 'Exit'];
  if(vc_investment_options && vc_investment_options['company_status'] == 'All'){
    dataArrangementVentureCapitalDeals = dataArrangementVentureCapitalDeals.filter((item) => item.axl_company_status !== 'Active').filter(
      (item) => !excludeDealTypes.includes(item.type_of_deal)
    );
  }

  let dataArrangementSet = vc_id?dataArrangementVentureCapitalDealsWithVCID:dataArrangementVentureCapitalDeals

  return (
    <>
      {options.staticLoading?<StaticLoadingWidget/>:''}
      {!hideHeader &&
      <>
      <Header label='Venture Capital' diseasesName='' quickNavItem='Deals' mainNav='homepage' />
      <div className="row mb-1">
        <div className="col">
          <h3>Investments</h3>
        </div>
        {/* <div className="col">
          {<span class="float-end">
            <ButtonGroup className="mb-1" aria-label="outlined primary button group">
              <Button onClick={() => handleView(false)} className={`btn-group ${!dataview && 'btn-active'}`} title="Title">
                <ListAltIcon/>
              </Button>
              <Button onClick={() => handleView(true)}  className={`btn-group ${dataview && 'btn-active'}`} title="Accordion">
                <AllInboxIcon/>
              </Button>
            </ButtonGroup>
          </span>}
        </div> */}
      </div>
      <div class="row">
        <HeaderPage label='' quickNavItem='VC' mainNav='' />
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <AccordionSearchBar searchVal={searchVal} findTrials={findTrials} />
        </div>
      </div>
      </>}
      {loading ? (
        <div style={{ marginTop: '20%' }}>
          <LoadingWidget />{' '}
        </div>
      ) : (
        <div className={`${vc_id?'':'card p-3'}`}>
          { dataview?
          <div class="deals-summary-table">
            {dataArrangementSet.length !== 0 && <InvestmentsGrid searchVal={searchVal} apiCallRequest={apiCallRequest} loadMoreRequest={loadMoreRequest} options={options} />}
            {dataArrangementSet.length == 0 && <h3 class='text-center'>No matching records found!</h3>}
          </div>
          : <><GeneralTable financials={dataArrangementSet} options={options} apiCallRequest={apiCallRequestTable} />
            {!hideHeader && <div class="col-12">
                {getCount >= limit && <div className='d-flex justify-content-center load-more-btn'>
                  <Button onClick={loadMoreRequest} style={{
                    border : '1px solid',
                    borderRadius : '3px',
                    padding: '2px 8px',
                    margin: '5px 0px',
                    color: 'black',
                    fontSize:'12px'
                  }}> Load More</Button>
              </div>}
            </div>}</>
          }
          </div>
      )}
    </>
  );
};

export default Investments;