import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWidget from '../../../containers/formField/LoadingWidget';
import { getCompanyPipelineData } from '../../../apiCalls/axiosCall/reportsAxios';
import { useParams, useLocation } from 'react-router-dom';
import Header from '../Header';
import GeneralTable from '../GeneralTable';
import { GetPlayersAllDispatchLevelLeaf } from '../../../reducers/DiseasesReducer';
import StaticLoadingWidget from '../../../containers/formField/StaticLoadingWidget';
import AccordionSearchBar from '../reusableComponents/AccordionSearchBar';
import { GetExitsAllDispatch, GetExitsAllWithVCDispatch } from '../../../reducers/DiseasesReducer';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { capitalizeFirstLetter } from '../Helper';

const DynamicExits = ({vc_exits_options=""}) => {
  const playersStore = useSelector((state) => state.diseases);
  const {exitsSummary, exitsWithVCSummary} = playersStore
  const [searchVal, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const dispatch = useDispatch();
  const { page, name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let search = params.get('search')?params.get('search'):'';
  let vc_id = vc_exits_options && vc_exits_options['vc_id'] ? vc_exits_options['vc_id'] : ''
  const options = {
    leafModalData:{},
    trialType:'exits',
    staticLoading:playersStore.staticLoading,
    columns:vc_id?{ 'axl_company_vc':'Company', 'acquiror':'Acquiror','total_enterprise_value':'Exit($)' ,'total_raised':'' ,'axl_deal_date':'Year', 'target_description':'Description'}:{ 'axl_company_vc':'Company', 'acquiror':'Acquiror','total_enterprise_value':'Exit($)' ,'total_raised':'' ,'axl_deal_date':'Year', 'target_description':'Description', 'axl_vc_allinvestors':'Investors'},
    showChips:true,
    // Key is the Location of Chip. Value is the which column show in the chips.
    chipColumn:{'axl_company_vc':'axl_company_category'},
  }

  const apiCallRequest = (data) => {
    setCompany(data)
  }

  if(company){
    options.leafModalData = {
      name:company['target'],
      nextData:company
    }
  }

  let searchItems = {
    search:searchVal,
    company_name:name,
    limit:500,
    offset:0,
    vc_id:vc_id,
  }
  useEffect(() => {
    if(search){
      setSearch(search)
    }
    if(vc_id){
      dispatch(GetExitsAllWithVCDispatch(id, searchItems, 'exits'));
    }else{
      dispatch(GetExitsAllDispatch(id, searchItems, 'exits'));
    }
  }, [name, id, dispatch, vc_id]);

  let dataArrangementSet = vc_id?exitsWithVCSummary:exitsSummary

  return (
    <>
      {options.staticLoading?<StaticLoadingWidget/>:''}
      {loading ? (
        <div style={{ marginTop: '20%' }}>
          <LoadingWidget />{' '}
        </div>
      ) : (
        <div className={`${vc_id?'':'card p-3'}`}>
          {dataArrangementSet && dataArrangementSet.data.length > 0 && <GeneralTable financials={dataArrangementSet.data} options={options} apiCallRequest={apiCallRequest} />}
        </div>
      )}
    </>
  );
};

export default DynamicExits;