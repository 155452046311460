// create a react component file that just takes a color prop and renders a dot of that color. The component should have a default color prop of green
import React from 'react';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const AxlDot = ({ color = 'green' }) => {
    return (
        <Brightness1Icon style={{color:color}} />
        // <div style={{ height: '10px', width: '10px', backgroundColor: color, borderRadius: '50%', marginTop: '65%' }}></div>
    );
}

export default AxlDot;