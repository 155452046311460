import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CLabel, CButton, CSubheader } from '@coreui/react';
import './ActiveTrials.css';
import { useDispatch, useSelector } from 'react-redux';
import { SetDiseaseDashboardView } from '../../reducers/UiReducer';
import { Breadcrumbs, Button, Typography } from '@material-ui/core';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import './Header.scss';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { bookmarkDiseasesPost, bookmarkDiseasesDelete, bookmarkDiseasesChecked } from '../../apiCalls/axiosCall/doctorPatientAxios';

const HeaderPage = ({ label, diseasesName, quickNavItem, mainNav='condition' }) => {
  const { diseaseName, diseaseId, page, id, name } = useParams();
  const location = useLocation();
  const path = location.pathname;
  const pathSegments = path.split('/');
  const lastSegment = pathSegments.pop() || pathSegments.pop(); // handle trailing slash if present
  const params = new URLSearchParams(location.search);
  let filter = params.get('filter')?params.get('filter'):'';

    const quickNavs = {
      'Drugs & Therapies' : [
        {
          label: 'Approved',
          path: `/Drug`,
        },
        {
          label: 'Reference',
          path: `/Brands`,
        },
        {
          label: 'Pricing',
          path: `/PricingEfficacy`,
        },
        // {
        //   label: 'Efficacy',
        //   path: `/PricingEfficacy`,
        // },
        // {
        //   label: 'Patents & Exclusivity',
        //   path: `/PatentsExclusivity`,
        // },
        {
          label: 'Trials',
          path: `/trialDrugs`,
        },
        {
          label: 'Modalities',
          path: `/modality?filter=Drugs%20and%20Therapies`,
        },
        {
          label: 'Target',
          path: `/target`,
        },
      ],
      'Devices' : [
        {
          label: 'Approved',
          path: `/Device`,
        },
        {
          label: 'Approved (510k)',
          path: `/Device510k`,
        },
        {
          label: 'Reference',
          path: `/DeviceBrands`,
        },
        {
          label: 'Trials',
          path: `/trialDevice`,
        },
      ],
      'VC' : [
        {
          label: 'Investments',
          path: `/Investments`,
        },
        {
          label: 'Firm',
          path: `/Firm`,
        },
        {
          label: 'Investors',
          path: `/VC-Investors`,
        },
      ],
      'Physicians' : [
        {
          label: 'All',
          path: `/AllPhysicians`,
        },
        {
          label: 'Company Payments',
          path: `/companyByPhysicians`,
        },
        {
          label: 'Physicians By Co',
          path: `/physiciansByCompany`,
        },
        {
          label: 'Physicians By Specialization',
          path: `/physiciansBySpecialization`,
        }
      ],
      'Public' : [
        {
          label: 'Dashboard',
          path: `/companyDashboard`,
        },
        {
          label: 'Transcript',
          path: `/transcript`,
        },
        {
          label: 'Company List',
          path: `/PublicCompanies`,
        }
      ],
    }

  return (
    <div>
      {!page && quickNavs[quickNavItem] && (
        quickNavs[quickNavItem].map((item) => (
          <Button variant='contained' 
            className={(path === item.path || filter.toLowerCase() === item.label.toLowerCase() ? 'mb-2 btn-quick-nav submenu active': 'mb-2 btn-quick-nav submenu')}
            style={item.customStyles}
            component={Link}
            to={item.path}
            onClick={item.onClick}
            disabled={item.disabled}
            style={{textTransform: item.label === 'CEOs' ? 'inherit' : ''}}
          >
            {item.label}
          </Button>
        ))
      )}
    </div>
  );
};

export default HeaderPage;
