import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, numberWithCommas } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { splitText } from './Helper';
import AxlChips from './reusableComponents/MUI/AxlChips';
import fda_logo from '../../assets/img/FDA-Logo.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
      fontSize: '2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function BrandModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  // console.log(item)
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item.name}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container modal-data-brand">
                    <div className="row pt-3">
                      <div className="col-12">
                        <h1 className="me-3">
                          <span class="me-2">
                            {item.name}
                          </span>
                        </h1>
                      </div>
                      <div className="col-12">
                        <table className="table table-borderless">
                        {item.brand.axl_generic &&
                          <tr>
                            <th>Generic Name:</th>
                            <td>
                              <div className="chips-container p-0 m-0">
                                <AxlChips options={{chips: item.brand.axl_generic, size: 'small'}} />
                              </div>
                            </td>
                          </tr>
                        }
                        {item.brand.axl_generic_class &&
                          <tr>
                            <th>Generic Class:</th><td>{item.brand.axl_generic_class}</td>
                          </tr>
                        }
                        {item.brand.axl_brand_RoA &&
                          <tr>
                            <th>RoA:</th><td>{splitText(item.brand.axl_brand_RoA,';',10)}</td>
                          </tr>
                        }
                        {item.brand.axl_company &&
                          <tr>
                            <th>Company:</th><td>{item.brand.axl_company}</td>
                          </tr>
                        }
                        {item.brand.axl_brand_condition &&
                          <tr>
                            <th>Condition or Use:</th>
                            <td>
                            <div className="chips-container p-0 m-0">
                                <AxlChips options={{chips: item.brand.axl_brand_condition, size: 'small'}} />
                              </div>
                            </td>
                          </tr>
                        }
                        </table>
                      </div>
                    </div>
                    <div className="row pt-3">
                        {item.brand.axl_brand_description && 
                        <>
                          <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                            <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2 m-0'>Description</h4>
                          </div>
                          <div className='col-12 mt-2 mb-2'>
                            {item.brand.axl_brand_description}
                          </div>
                        </>}
                        {item.brand.axl_brand_moa && 
                        <>
                          <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                            <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2 m-0'>MoA</h4>
                          </div>
                          <div className='col-12 mt-2 mb-2'>
                            {item.brand.axl_brand_moa}
                          </div>
                        </>}
                        {item.brand.axl_fda_url && 
                        <div className="col-12 mb-3">
                        <Link
                            target="_blank"
                            to={{
                            pathname: item.brand.axl_fda_url,
                            }}>
                            <img src={fda_logo} width="45px" />
                        </Link>
                        </div>}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default BrandModal;