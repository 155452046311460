import { createSlice } from '@reduxjs/toolkit';
import { getEfficacyTable, getEfficacyTableByCategory, getTrialsData, getTrialsDataByCategory, getTreatmentsByCondition, getTreatmentsByCategory, getModality, getModalityItems, getTechnology, getTarget, getTreatments, getSelectedPapers, getBrands, getOrangebookProducts, getDrugPricing, getVentureCapital, getPeople, getVentureCapitalDeals, listOfFeaturedDiseases, getUserModules, getUserModulesPermission, getDevicePma, getGeneric, getDevice510k, getProducts, getPurpleBook } from '../apiCalls/backendUrl';
import axios from 'axios';

const config = {
  headers: {
    Authorization: `Token 03314be0379da6d92a7ae3e680ab6879f6b00f2c`,
  },
};

const serialize = function(obj) {
  var str = [];
  if(obj){
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
      let string = str.join("&");
      let search = ''
      if(Object.keys(obj).length !== 0){
        search = '?'+string
      }
      return search
  }else{
    return ''
  }
}

const TrialsReducer = createSlice({
  name: 'trials',
  initialState: {
    activeTrialsData: {},
    activeTrialsDataLevelLeaf:{},
    dataArrangement:[],
    dataArrangementTrials:[],
    dataArrangementApproved:[],
    dataArrangementVentureCapital:[],
    dataArrangementVentureCapitalDeals:[],
    dataArrangementVentureCapitalDealsWithVCID:[],
    trialsByPhasesData: {},
    allTrialsData: {},
    filteredTrials: [],
    activeTrials2: {
      'Tests & Imaging': {
        approved: {
          'Diagnostic Test': [0, 1, 2],
          Imaging: [0, 1],
        },
        active: {
          'Diagnostic Test': [0, 1, 2, 3, 4],
          Imaging: [0, 1, 2, 3, 4],
        },
        novel: {
          'Diagnostic Test': [0, 1],
          Imaging: [0, 1],
        },
      },
      'Devices & Procedures': {
        approved: {
          Device: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          Instrument: [0, 1, 2],
          Equipment: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          Procedure: [],
        },
        active: {
          Device: [0, 1, 2, 3, 4, 5],
          Instrument: [0, 1, 2, 3, 4],
          Equipment: [0, 1, 2, 3],
          Procedure: [0, 1, 2],
        },
        novel: {
          Device: [0, 1],
          Instrument: [0, 1, 2],
          Equipment: [0, 1, 2],
          Procedure: [0, 1, 2],
        },
      },
      'Drugs, Vaccines & New Therapies': {
        approved: {
          Drug: [],
          'Drug Delivery': [],
          Vaccine: [],
          'New Therapy': [],
        },
        active: {
          I: {
            Drug: [0, 1, 2],
            'Drug Delivery': [0, 1],
            Vaccine: [],
            'New Therapy': [],
          },
          II: {
            Drug: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [0],
          },
          III: {
            Drug: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [],
          },
          IV: {
            Drug: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [],
          },
        },
        novel: {
          I: {
            Drug: [0, 1, 2],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [],
          },
          II: {
            Drug: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [0],
          },
          III: {
            Drug: [0, 1, 2, 3, 4, 5, 6, 7],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [],
          },
          IV: {
            Drug: [0, 1, 2, 3, 4, 5, 6],
            'Drug Delivery': [],
            Vaccine: [],
            'New Therapy': [],
          },
        },
      },
    },
    drugsVaccClicked: false,
    drugsVaccApprovedClicked: false,
    drugsVaccActiveClicked: false,
    drugsVaccNovelClicked: false,
    activeTrialsModal: false,
    modalRowHeader: '',
    modalColumnHeader: '',
    modalRowCount: '',
    trialsByPhaseVisible: false,
    modalData: [],
    diagnosticTestApproved: [],
    row: '',
    stage: '',
    subStage: '',
    allSubstageData: { activeData: [], novelData: [] },
    allTrials: [],
    efficacyTable: { columns: [], data: [] },
    treatment: {},
    treatmentDataLevelLeaf:{},
    loading: false,
    staticLoading:false,
    modalityData: {},
    modalityDataLevelLeaf:{},
    level_2_key:0,
    getCount:0,
    getGrandParentCount:0,
    efficacyList:{},
    efficacyCategoryTable:{},
    efficacyCategoryList:[],
    technologyData:{},
    targetData:{},
    targetDataSearch:'',
    selectedPapersData:[],
    ventureCapitalSummary:[],
    peopleSummary:'',
    conditionList:[],
    conditionListSearch:'',
    userPermissionConditionList:[],
    userModulesList:[],
    userModulesPermissionList:'',
    brandsSummaryList:'',
    devicePmaList:'',
    device510kList:[],
    genericList:'',
    AllTrialsSummaryFull:'',
    getTargetSummaryData:'',
    productList:'',
    purpleBookList:'',
  },
  reducers: {
    setActiveTrialsData(state, action) {
      state.activeTrialsData = action.payload;
    },
    setActiveTrialsDataLevelLeaf(state, action) {
      state.activeTrialsDataLevelLeaf = action.payload;
    },
    setTrialsByPhasesData(state, action) {
      state.trialsByPhasesData = action.payload;
    },
    setAllTrialsData(state, action) {
      state.allTrialsData = action.payload;
    },
    setCount(state, action) {
      state.getCount = action.payload;
    },
    setGrandParentCount(state, action) {
      state.getGrandParentCount = action.payload;
    },
    toggleTrialsByPhaseVisible(state, action) {
      state.trialsByPhaseVisible = !state.trialsByPhaseVisible;
    },
    toggleDrugsVaccApproved(state, action) {
      state.drugsVaccActiveClicked = false;
      state.drugsVaccNovelClicked = false;
      state.drugsVaccApprovedClicked = !state.drugsVaccApprovedClicked;
    },
    toggleDrugsVaccActive(state, action) {
      state.drugsVaccApprovedClicked = false;
      state.drugsVaccNovelClicked = false;
      state.drugsVaccActiveClicked = !state.drugsVaccActiveClicked;
    },
    toggleDrugsVaccNovel(state, action) {
      state.drugsVaccApprovedClicked = false;
      state.drugsVaccActiveClicked = false;
      state.drugsVaccNovelClicked = !state.drugsVaccNovelClicked;
    },
    toggleActiveTrialsModal(state, action) {
      state.activeTrialsModal = !state.activeTrialsModal;
    },
    setActiveTrialsModalDetails(state, action) {
      const {
        rowHeader,
        columnHeader,
        rowCount,
        modalData,
        row,
        stage,
        subStage,
      } = action.payload;
      console.log(row, stage, subStage);
      state.modalRowHeader = rowHeader !== undefined && rowHeader;
      state.modalColumnHeader = columnHeader !== undefined && columnHeader;
      state.modalRowCount = rowCount !== undefined && rowCount;
      state.modalData = modalData !== undefined && modalData;
      state.row = row !== undefined ? row : '';
      state.stage = stage !== undefined ? stage : '';
      state.subStage = subStage !== undefined ? subStage : '';
    },
    changeModalData(state, action) {
      state.modalData = action.payload;
    },
    changeColumnHeader(state, action) {
      state.modalColumnHeader = action.payload;
    },
    deleteSubstage(state, action) {
      state.subStage = '';
      state.stage = '';
    },
    setAllSubstageData(state, action) {
      state.allSubstageData.activeData = action.payload.activeData;
      state.allSubstageData.novelData = action.payload.novelData;
    },
    setDrugsVaccClicked(state, action) {
      state.drugsVaccClicked = !state.drugsVaccClicked;
      state.drugsVaccActiveClicked = false;
      state.drugsVaccNovelClicked = false;
      state.drugsVaccApprovedClicked = false;
    },
    setAllTrials(state, action) {
      state.allTrials = [...action.payload];
    },
    setEfficacyTable(state, action) {
      state.efficacyTable.columns = action.payload.columns;
      state.efficacyTable.data = action.payload.data;
    },
    setEfficacyCategoryTable(state, action) {
      let {
        dataResults,
        totalCount,
        offset = 0,
      } = action.payload;
      if(offset==0){
        state.efficacyCategoryList = []
      }
      state.getCount = totalCount
      state.efficacyCategoryTable = action.payload;
      state.efficacyCategoryList = state.efficacyCategoryList.concat(dataResults)
    },
    setEfficacyNameList(state, action) {
      state.efficacyList = action.payload.data;
    },
    setTreatmentData(state, action) {
      state.treatment = action.payload;
    },
    setTreatmentDataLevelLeaf(state, action) {
      state.treatmentDataLevelLeaf = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setStaticLoading(state, action) {
      state.staticLoading = action.payload;
    },
    setModalityData(state, action) {
      state.modalityData = action.payload;
    },
    setTechnologyData(state, action) {
      state.technologyData = action.payload;
    },
    setTargetData(state, action) {
      state.targetData = action.payload;
    },
    setTargetSearchData(state, action) {
      state.targetDataSearch = action.payload;
    },
    setSelectedPapersData(state, action) {
      state.selectedPapersData = action.payload;
    },
    setModalityDataLevelLeaf(state, action) {
      state.modalityDataLevelLeaf = action.payload;
    },
    setVentureCapitalSummaryData(state, action) {
      state.ventureCapitalSummary = action.payload;
    },
    setPeopleSummaryData(state, action) {
      state.peopleSummary = action.payload;
    },
    setConditionData(state, action) {
      state.conditionList = action.payload;
    },
    setConditionDataSearch(state, action) {
      state.conditionListSearch = action.payload;
    },
    setUserPermissionConditionData(state, action) {
      state.userPermissionConditionList = action.payload;
    },
    setUserModulesData(state, action) {
      state.userModulesList = action.payload;
    },
    setUserModulesPermissionData(state, action) {
      state.userModulesPermissionList = action.payload;
    },
    setBrandsSummaryData(state, action) {
      state.brandsSummaryList = action.payload;
    },
    setDevicePmaSummaryData(state, action) {
      state.devicePmaList = action.payload;
    },
    setPurpleBookSummaryData(state, action) {
      state.purpleBookList = action.payload;
    },
    setProductSummaryData(state, action) {
      state.productList = action.payload;
    },
    setDevices510kData(state, action) {
      state.device510kList = action.payload;
    },
    setGenericSummaryData(state, action) {
      state.genericList = action.payload;
    },
    setAllTrialsSummaryFull(state, action){
      state.AllTrialsSummaryFull = action.payload;
    },
    setTargetSummaryData(state, action){
      state.getTargetSummaryData = action.payload;
    },
    setDataArrangement(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangement = []
        }
        state.dataArrangement = Array.from(new Set(state.dataArrangement.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangement = state.dataArrangement.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangement = state.dataArrangement.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangement = state.dataArrangement.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
    setDataArrangementTrials(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementTrials = []
        }
        state.dataArrangementTrials = Array.from(new Set(state.dataArrangementTrials.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementTrials = state.dataArrangementTrials.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementTrials = state.dataArrangementTrials.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangementTrials = state.dataArrangementTrials.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
    setDataArrangementApproved(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementApproved = []
        }
        state.dataArrangementApproved = Array.from(new Set(state.dataArrangementApproved.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementApproved = state.dataArrangementApproved.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementApproved = state.dataArrangementApproved.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangementApproved = state.dataArrangementApproved.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
    setDataArrangementVentureCapital(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementVentureCapital = []
        }
        state.dataArrangementVentureCapital = Array.from(new Set(state.dataArrangementVentureCapital.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementVentureCapital = state.dataArrangementVentureCapital.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementVentureCapital = state.dataArrangementVentureCapital.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangementVentureCapital = state.dataArrangementVentureCapital.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
    setDataArrangementVentureCapitalDeals(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementVentureCapitalDeals = []
        }
        state.dataArrangementVentureCapitalDeals = Array.from(new Set(state.dataArrangementVentureCapitalDeals.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementVentureCapitalDeals = state.dataArrangementVentureCapitalDeals.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementVentureCapitalDeals = state.dataArrangementVentureCapitalDeals.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangementVentureCapitalDeals = state.dataArrangementVentureCapitalDeals.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
    setDataArrangementVentureCapitalDealsWithVCID(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementVentureCapitalDealsWithVCID = []
        }
        state.dataArrangementVentureCapitalDealsWithVCID = Array.from(new Set(state.dataArrangementVentureCapitalDealsWithVCID.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementVentureCapitalDealsWithVCID = state.dataArrangementVentureCapitalDealsWithVCID.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementVentureCapitalDealsWithVCID = state.dataArrangementVentureCapitalDealsWithVCID.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level4'){
        state.dataArrangementVentureCapitalDealsWithVCID = state.dataArrangementVentureCapitalDealsWithVCID.map((item,index) => (
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
               {
                ...Nitem,
                nextData: Nitem.nextData.map((Eitem,i) => (
                  Eitem.name == levelSelect ? 
                  {
                  ...Eitem,
                  nextData : Array.from(new Set(Eitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                  offset: limit+offset,
                  tot_row_count : state.getCount
                }:{
                  ...Eitem, nextData: Eitem.nextData?Eitem.nextData:[]
                })),
              }
            ))
          }
        ))
      }
    },
  },
});

const { actions } = TrialsReducer;

export const {
  setActiveTrialsData,
  setActiveTrialsDataLevelLeaf,
  setTrialsByPhasesData,
  setAllTrialsData,
  toggleTrialsByPhaseVisible,
  toggleDrugsVaccApproved,
  toggleDrugsVaccActive,
  toggleDrugsVaccNovel,
  toggleActiveTrialsModal,
  setActiveTrialsModalDetails,
  changeModalData,
  changeColumnHeader,
  deleteSubstage,
  setAllSubstageData,
  setDrugsVaccClicked,
  setAllTrials,
  setEfficacyTable,
  setEfficacyCategoryTable,
  setEfficacyNameList,
  setTreatmentData,
  setTreatmentDataLevelLeaf,
  setModalityData,
  setLoading,
  setStaticLoading,
  setDataArrangement,
  setDataArrangementTrials,
  setDataArrangementApproved,
  setDataArrangementVentureCapital,
  setDataArrangementVentureCapitalDeals,
  setModalityDataLevelLeaf,
  setCount,
  setGrandParentCount,
  setTechnologyData,
  setTargetData,
  setSelectedPapersData,
  setVentureCapitalSummaryData,
  setPeopleSummaryData,
  setConditionData,
  setConditionDataSearch,
  setUserPermissionConditionData,
  setUserModulesData,
  setUserModulesPermissionData,
  setBrandsSummaryData,
  setDevicePmaSummaryData,
  setGenericSummaryData,
  setAllTrialsSummaryFull,
  setDevices510kData,
  setTargetSummaryData,
  setDataArrangementVentureCapitalDealsWithVCID,
  setProductSummaryData,
  setPurpleBookSummaryData,
  setTargetSearchData,
} = actions;

// export const getAllTrialsData = (diseaseId,val='') => async (dispatch) => {
//   try {
//     let search = val?'?search='+val:'';
//     dispatch(setLoading(true));
//     const { data } = await axios.get(`${getTrialsData}/${diseaseId}${search}`, config);
//     dispatch(setAllTrialsData(data.data));
//     dispatch(setLoading(false));
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getAllTrialsData = (diseaseId,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsData}/${diseaseId}${search}`, config);
    dispatch(setAllTrialsData(data));
    dispatch(setAllTrialsSummaryFull(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementTrials({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTrialsCategoryData = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsDataByCategory}/${intervention_category}${search}`, config);
    dispatch(setAllTrialsData(data.data));
    dispatch(setAllTrialsSummaryFull(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementTrials({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTrialsData = (diseaseId,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsData}/${diseaseId}/active${search}`, config);
    dispatch(setActiveTrialsData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementTrials({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTrialsCategoryData = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsDataByCategory}/${intervention_category}/active${search}`, config);
    dispatch(setActiveTrialsData(data.data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementTrials({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTrialsDataLevelLeaf = (diseaseId,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsData}/${diseaseId}/active${search}`, config);
    dispatch(setActiveTrialsDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTrialsCategoryDataLevelLeaf = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsDataByCategory}/${intervention_category}/active${search}`, config);
    dispatch(setActiveTrialsDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTrialsDataLevelLeaf = (diseaseId,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsData}/${diseaseId}${search}`, config);
    dispatch(setActiveTrialsDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTrialsCategoryDataLevelLeaf = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(`${getTrialsDataByCategory}/${intervention_category}${search}`, config);
    dispatch(setActiveTrialsDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTrialsByPhasesData = (diseaseId, searchItems, trialClassification='') => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTrialsData}/${diseaseId}/phases`;
    if (trialClassification) {
      url = `${url}/${trialClassification}`;
    }
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTrialsByPhasesData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTrialsByPhasesCategoryData = (intervention_category, searchItems, trialClassification='') => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTrialsDataByCategory}/${intervention_category}/phases`;
    if (trialClassification) {
      url = `${url}/${trialClassification}`;
    }
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTrialsByPhasesData(data.data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getActiveTrialsCellInfo =
  // WRITE NEW SIMILAR FUNCTION FOR PHASES


    ({
      rowHeader,
      columnHeader,
      rowCount,
      modalData,
      row,
      modalColumnHeader,
      stage,
    }) =>
    async (dispatch) => {
      // console.log(activeTrialsData['Tests & Imaging'].approved['Diagnostic Test']);
      // console.log(activeTrialsData['Drugs, Vaccines & New Therapies'].active['I']['Drug'])
      console.log(
        rowHeader,
        columnHeader,
        rowCount,
        modalData,
        row,
        modalColumnHeader,
        stage
      );
      try {
        const split = columnHeader.split(' ');
        const I = split.includes('I');
        const II = split.includes('II');
        const III = split.includes('III');
        const IV = split.includes('IV');
        console.log(I, II, III, IV);
        const subStage = I
          ? 'I'
          : II
          ? 'II'
          : III
          ? 'III'
          : IV
          ? 'IV'
          : undefined;
        console.log(subStage);
        await dispatch(
          setActiveTrialsModalDetails({
            rowHeader,
            columnHeader,
            rowCount,
            modalData,
            row,
            stage,
            subStage,
          })
        );
        // columnHeader === 'Approved' && modalColumnHeader !== 'Approved'
        //   ? await dispatch(toggleDrugsVaccApproved())
        //   : columnHeader === 'Active Trials' &&
        //     modalColumnHeader !== 'Active Trials'
        //   ? await dispatch(toggleDrugsVaccActive())
        //   : columnHeader === 'Novel Trials' &&
        //     modalColumnHeader !== 'Novel Trials'
        //   ? await dispatch(toggleDrugsVaccNovel())
        //   : console.log('error with getActiveTrialsCellInfo');
        dispatch(toggleActiveTrialsModal());
      } catch (error) {
        console.log(error);
      }
    };

export const getEfficacyTableReducer = (condition_id, filter) => async (dispatch) => {
  try {
    let filterArr = [], url = `${getEfficacyTable}/${condition_id}/table`;
    // Loop through the filter object and create an array of query params
    for (let key in filter) {
      if (filter[key]) {
        filterArr.push(`${key}=${filter[key].title}`);
      }
    }
    // Add the filter params to the URL
    if (filterArr.length > 0) {
      url += "?" + filterArr.join("&");
    }

    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setEfficacyTable(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getEfficacyCategoryTableReducer = (catetory, searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getEfficacyTableByCategory}/${catetory}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    let dataResults = data.data
    let totalCount = data.count
    const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setEfficacyCategoryTable({dataResults, totalCount, offset}));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getEfficacyNameList = (catetory) => async (dispatch) => {
  try {
    let url = `${getEfficacyTableByCategory}/${catetory}`;
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setEfficacyNameList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTreatmentDataReducer = (condition_id,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatmentsByCondition}`;
    // if condition_id is passed in, add it to the URL
    if (condition_id) {
      url += `/${condition_id}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementApproved({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTreatmentDataReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatments}${search}`;

    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementApproved({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTreatmentCategoryDataReducer = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatmentsByCategory}`;
    // if intervention_category is passed in, add it to the URL
    if (intervention_category) {
      url += `/${intervention_category}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementApproved({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getGenericDataReducer = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getEfficacyTableByCategory}`;
    // if intervention_category is passed in, add it to the URL
    if (intervention_category) {
      url += `/${intervention_category}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementApproved({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getGenericDataLevelLeaf = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getEfficacyTableByCategory}`;
    // if intervention_category is passed in, add it to the URL
    if (intervention_category) {
      url += `/${intervention_category}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTreatmentCategoryDataLevelLeaf = (intervention_category,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatmentsByCategory}`;
    // if intervention_category is passed in, add it to the URL
    if (intervention_category) {
      url += `/${intervention_category}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTreatmentDataLevelLeaf = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatments}${search}`;
    // if intervention_category is passed in, add it to the URL
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getTreatmentDataLevelLeaf = (condition_id,searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTreatmentsByCondition}`;
    // if condition_id is passed in, add it to the URL
    if (condition_id) {
      url += `/${condition_id}${search}`;
    }
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTreatmentDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getModalityDataLevelLeaf = (searchItems,arrItem={}) => async (dispatch) => {
  try {
    let url = `${getModality}`;
    if(searchItems.input_type){
      url += `/${searchItems.input_type}`
    }
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setModalityDataLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getModalityReducer = (searchItems,arrItem={}) => async (dispatch) => {
  try {
    let url = `${getModality}`;
    if(searchItems.input_type){
      url += `/${searchItems.input_type}`
    }
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setModalityData(data.data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = arrItem.con_filter_by?arrItem.con_filter_by:'';
      const levelSelectParent = arrItem.con_filter_by_2?arrItem.con_filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getModalityItemsReducer = (searchItems,arrItem={}) => async (dispatch) => {
  try {
    let url = `${getModalityItems}`;
    // if(searchItems.input_type){
    //   url += `/${searchItems.input_type}`
    // }
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setModalityData(data.data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = arrItem.con_filter_by?arrItem.con_filter_by:'';
      const levelSelectParent = arrItem.con_filter_by_2?arrItem.con_filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getTechnologyItemsReducer = () => async (dispatch) => {
  try {
    let url = `${getTechnology}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTechnologyData(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getTargetItemsReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTarget}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTargetData(data.data));
    dispatch(setTargetSummaryData(data));
    dispatch(setCount(data.count))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getTargetItemsSearchReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getTarget}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTargetSearchData(data.data));
    dispatch(setCount(data.count))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getSingleTargetReducer = (target_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getTarget + (target_id ? "/" + target_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setTargetSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getSelectedPapersReducer = (searchItems) => async (dispatch) => {
  try {
    let url = `${getSelectedPapers}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setSelectedPapersData(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getBrandsDataReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getBrands;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setBrandsSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleBrandsDispatch = (brand_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getBrands + (brand_id ? "/" + brand_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setBrandsSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getDevicePmaDataReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getDevicePma;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setDevicePmaSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleDevicePmaDispatch = (device_pma_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getDevicePma + (device_pma_id ? "/" + device_pma_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setDevicePmaSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getOrangebookProductsReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getOrangebookProducts;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getDrugPricingReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getDrugPricing;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getVentureCapitalReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getVentureCapital;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setVentureCapitalSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangementVentureCapital({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleVentureCapitalDispatch = (venture_capital_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getVentureCapital + (venture_capital_id ? "/" + venture_capital_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setVentureCapitalSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getVentureCapitalDealsReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getVentureCapitalDeals;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setVentureCapitalSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangementVentureCapitalDeals({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getVentureCapitalDealsWithVCIDReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getVentureCapitalDeals;
    url = `${url}${search}`;
    // dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setVentureCapitalSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangementVentureCapitalDealsWithVCID({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleVentureCapitalDealsDispatch = (venture_capital_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getVentureCapitalDeals + (venture_capital_id ? "/" + venture_capital_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setVentureCapitalSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getPeopleReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getPeople;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setPeopleSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSinglePeopleDispatch = (venture_capital_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getPeople + (venture_capital_id ? "/" + venture_capital_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setPeopleSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getConditionReducer = (searchItems) => async (dispatch) => {
  try {
    let url = `${listOfFeaturedDiseases}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setCount(data.count))
    dispatch(setConditionData(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getUserPermissionConditionReducer = (searchItems) => async (dispatch) => {
  try {
    let url = `${listOfFeaturedDiseases}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setUserPermissionConditionData(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getConditionReducerSearch = (searchItems) => async (dispatch) => {
  try {
    let url = `${listOfFeaturedDiseases}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setCount(data.count))
    dispatch(setConditionDataSearch(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getSingleConditionDispatch = (condition_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = listOfFeaturedDiseases + (condition_id ? "/" + condition_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setConditionData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getUserModulesReducer = (searchItems) => async (dispatch) => {
  try {
    let url = `${getUserModules}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setUserModulesData(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getUserModulesPermissionReducer = (searchItems) => async (dispatch) => {
  try {
    let url = `${getUserModulesPermission}`;
    url += serialize(searchItems)
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setUserModulesPermissionData(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const getGenericReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getGeneric;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setGenericSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleGenericDispatch = (generic_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getGeneric + (generic_id ? "/" + generic_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setGenericSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getDevices510kDataReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = `${getDevice510k}`+search;
    // if intervention_category is passed in, add it to the URL
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setDevices510kData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setDataArrangementApproved({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getProductsDataReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getProducts;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setProductSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getPurpleBookReducer = (searchItems) => async (dispatch) => {
  try {
    let search = serialize(searchItems)
    let url = getPurpleBook;
    url = `${url}${search}`;
    dispatch(setStaticLoading(true));
    const { data } = await axios.get(url, config);
    dispatch(setPurpleBookSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};


export default TrialsReducer;
