import React, { useEffect, useState, setState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useLocation, useParams } from 'react-router-dom';
import { CCol, CFormGroup, CInput, CLabel } from '@coreui/react';
import SimpleReactValidator from 'simple-react-validator';
import LoadingWidget from '../../containers/formField/LoadingWidget';
import Header from './Header';
import DisplayTextEditorContent from './DisplayTextEditorContent';
import { diseaseCategoryDetail } from '../../apiCalls/axiosCall/doctorPatientAxios';
import ActiveTrialsGrid from './ActiveTrialsGrid';
import Conditions from './Conditions';
import './ActiveTrials.css';
import ActiveTrialsGrid2 from './ActiveTrialsGrid2';
import ActiveTrialsModal from './ActiveTrialsModal';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTrialsCategoryData, getActiveTrialsCategoryDataLevelLeaf, getTrialsByPhasesData } from '../../reducers/TrialsReducer';
import ActiveTrialsPhasesSection from './ActiveTrialsPhasesSection';
import { MenuItem, Select, TextField } from '@material-ui/core';
import AccordionSearchBar from './reusableComponents/AccordionSearchBar';
import StaticLoadingWidget from '../../containers/formField/StaticLoadingWidget';
import AccordionCheckFilters from './reusableComponents/AccordionCheckFilters';
import { ButtonGroup, Button } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import GeneralTable from './GeneralTable';
import { Autocomplete } from '@material-ui/lab';
import { capitalizeFirstLetter } from './Helper';

const ActiveTrials = () => {
  const { diseaseName, diseaseId } = useParams();
  const [selection, setSelection] = useState('axl_primary_intervention');
  const [loading, setLoading] = useState(true);
  const trials = useSelector((state) => state.trials);
  const { AllTrialsSummaryFull, activeTrialsData, getGrandParentCount, dataArrangement, getCount } = trials;
  const [searchVal, setSearch] = useState('');
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [level4, setLevel4] = useState(null);
  let [limit, setLimit] = useState(100);
  let [offset, setOffset] = useState(0);
  const [levelLeaf, setlevelLeaf] = useState(null);
  const [dataview, setDataview] = useState(false);
  const [company, setCompany] = useState(null);
  const [axlPrimaryIntervention, setAxlPrimaryIntervention] = useState('');
  const [axlCategory, setAxlCategory] = useState('Drug');
  const [axlClass, setAxlClass] = useState('');
  const [axlPhase, setAxlPhase] = useState('Phase 3');
  const [axlStatus, setAxlStatus] = useState('Completed');
  const [axlCompany, setAxlCompany] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let search = params.get('search')?params.get('search'):'';
  const category_type = 'all'
  let options = {
    dataSwitch:selection,
    searchVal:searchVal,
    item:[],
    no_of_keys:2,
    leafKey:'',
    leafValue:'',
    extraRow:selection=='condition'?true:'',
    trialType:'trials',
    level2:level2,
    level3:level3,
    level4:level4,
    levelLeaf:levelLeaf,
    staticLoading:trials.staticLoading,
    leafModalData:{},
    offset_limit:offset+limit,
    dataCount:getGrandParentCount,
    columns:{'axl_start_date':'Date', 'axl_primary_intervention':'', 'axl_brief_title':'Title', 'axl_company':''},
    showChips:true,
    // Key is the Location of Chip. Value is the which column show in the chips.
    chipColumn:{'axl_primary_intervention':['axl_intervention_category','axl_phase','enrollment','axl_status'], 'axl_company':['nct_number']},
  }
  // const group_by = 'axl_intervention_category'

  let [checkBoxs, setCheckBoxs] = useState([
    {key:'Company', value:true, label:'Company'},
    {key:'University,Hospital', value:false, label:'University/Hospital'},
    {key:'Public', value:false, label:'Public'},
    {key:'Agency', value:false, label:'Agency/Foundation'},
    {key:'Individual', value:false, label:'Individual'},
  ])

  let [string, setString] = useState('Company');

  const updateCheckBoxs = (newItems, checkKeys) => {
    setCheckBoxs(newItems);
    setString(checkKeys);
  };

  const group_by = {
    axl_primary_intervention:['axl_intervention_category', 'axl_class1', 'axl_primary_intervention'],
    axl_company:['axl_company_category','axl_company','axl_primary_intervention'],
    condition:['condition_specialization','condition_name','axl_primary_intervention']
  }
  let searchItems = {
    group_by : group_by[selection][0],
    level:'level1',
    is_frontend:true,
    limit: limit,
    offset: offset,
    selected_filter: selection,
    search:search,
    search_filters:string,
    is_view : dataview?'true':'false',
    phase:axlPhase,
    is_enrollment_filters:'true',
    trial_class:axlClass,
    trial_category:axlCategory,
    trial_primary_intervention:axlPrimaryIntervention,
    axl_status:axlStatus,
    axl_company:axlCompany,
  }
  useEffect(() => {
      if(search){
        setSearch(search)
      }
      dispatch(getActiveTrialsCategoryData(category_type, searchItems));
  }, [category_type,offset,selection, string, dataview, axlClass, axlCategory, axlPrimaryIntervention, axlPhase, axlStatus, axlCompany]);
  let loadMoreRequest = () => {
    setOffset(offset+limit)
  }
  const findTrials = (val) => {
    let searchItems = {
      group_by : group_by[selection][0],
      level:'level1',
      search : val,
      is_frontend:true,
      limit: limit,
      offset: 0,
      selected_filter: selection,
      search_filters:string,
      is_view : dataview?'true':'false',
      phase:axlPhase,
      is_enrollment_filters:'true',
      trial_class:axlClass,
      trial_category:axlCategory,
      trial_primary_intervention:axlPrimaryIntervention,
      axl_status:axlStatus,
      axl_company:axlCompany,
    }
    setSearch(val)
    dispatch(getActiveTrialsCategoryData(category_type,searchItems));
  };

  const handleSelect = (e) => {
    if(e.target.value == 'axl_company'){
      let checkBoxLists = [
        {key:'Company', value:true, label:'Company'},
        {key:'University,Hospital', value:true, label:'University/Hospital'},
        {key:'Public', value:true, label:'Public'},
        {key:'Agency', value:false, label:'Agency/Foundation'},
        {key:'Individual', value:false, label:'Individual'},
      ]
      setCheckBoxs(checkBoxLists);
      setString('Company,University,Hospital,Public');
    }
    setSelection(e.target.value)
    setOffset(0)
  }

  const apiCallRequest1 = (val, id, is_leaf, offset, level, apiRun, parent, sub_patent) => {
    let searchItems = {}
    if(level == 'level2'){
      setLevel2(val)
      searchItems = {
        group_by : group_by[selection][1],
        parent:group_by[selection][0],
        filter_by : val,
        level:'level2',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        id:id,
        is_leaf_back:is_leaf,
        search_filters:string
      }
    }
    if(level == 'level3'){
      setLevel3(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : val,
        filter_by_2 : parent,
        level:'level3',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        id:id,
        is_leaf_back:is_leaf,
        search_filters:string
      }
    }
    if(level == 'level4'){
      setLevel4(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : val,
        filter_by_2 : parent,
        filter_by_3 : sub_patent,
        level:'level4',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        id:id,
        is_leaf_back:is_leaf,
        search_filters:string
      }
    }
    if(level == 'levelLeaf'){
      setlevelLeaf(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : sub_patent?sub_patent:'Other',
        filter_by_2 : parent,
        filter_by_3 : val,
        is_leaf : true,
        search:searchVal,
        is_frontend:true,
        selected_filter: selection,
        id:id,
      }
    }
    if(apiRun == 'no'){
      dispatch(getActiveTrialsCategoryData(category_type,searchItems));
    }
    if(apiRun == 'leaf_no'){
      dispatch(getActiveTrialsCategoryDataLevelLeaf(category_type,searchItems));
    }
    options.leafModalData = {}
  }

  const apiCallRequest = (val, offset, level, apiRun, parent, sub_patent) => {
    let searchItems = {}
    if(level == 'level2'){
      setLevel2(val)
      searchItems = {
        group_by : group_by[selection][1],
        parent:group_by[selection][0],
        filter_by : val,
        level:'level2',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        search_filters:string
      }
    }
    if(level == 'level3'){
      setLevel3(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : val,
        filter_by_2 : parent,
        level:'level3',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        search_filters:string
      }
    }
    if(level == 'level4'){
      setLevel4(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : val,
        filter_by_2 : parent,
        filter_by_3 : sub_patent,
        level:'level4',
        search:searchVal,
        is_frontend:true,
        limit: limit,
        offset: offset,
        selected_filter: selection,
        search_filters:string
      }
    }
    if(level == 'levelLeaf'){
      setlevelLeaf(val)
      searchItems = {
        group_by : group_by[selection][2],
        parent:group_by[selection][0],
        sub_parent:group_by[selection][1],
        filter_by : sub_patent?sub_patent:'Other',
        filter_by_2 : parent,
        filter_by_3 : val,
        is_leaf : true,
        search:searchVal,
        is_frontend:true,
        selected_filter: selection
      }
    }
    if(apiRun == 'no'){
      dispatch(getActiveTrialsCategoryData(category_type,searchItems));
    }
    if(apiRun == 'leaf_no'){
      dispatch(getActiveTrialsCategoryDataLevelLeaf(category_type,searchItems));
    }
    options.leafModalData = {}
  }

  const apiCallRequestTable = (data) =>{
    setCompany(data)
  }

  if(company){
    options.leafModalData = {
      name:company['nct_number'],
      nextData:[company]
    }
  }

  const handleView = (v) =>{
    setDataview(v)
    setOffset(0)
  }

  const filterClassOptions = AllTrialsSummaryFull && AllTrialsSummaryFull['enrollment_filters'] && AllTrialsSummaryFull['enrollment_filters']['class'].map((cls) => {
    return capitalizeFirstLetter(cls);
  });

  const filterCategoryOptions = AllTrialsSummaryFull && AllTrialsSummaryFull['enrollment_filters'] && AllTrialsSummaryFull['enrollment_filters']['intervention_category'].map((cls) => {
    return capitalizeFirstLetter(cls);
  });

  const filtersetAxlPrimaryInterventionOptions = AllTrialsSummaryFull && AllTrialsSummaryFull['enrollment_filters'] && AllTrialsSummaryFull['enrollment_filters']['primary_intervention'].map((cls) => {
    return capitalizeFirstLetter(cls);
  });

  const filterStatusOptions = AllTrialsSummaryFull && AllTrialsSummaryFull['enrollment_filters'] && AllTrialsSummaryFull['enrollment_filters']['status'].map((cls) => {
    return capitalizeFirstLetter(cls);
  });

  const filterCompanyOptions = AllTrialsSummaryFull && AllTrialsSummaryFull['enrollment_filters'] && AllTrialsSummaryFull['enrollment_filters']['company'].map((cls) => {
    return capitalizeFirstLetter(cls);
  });

  const filterPhaseOptions = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4']

  return (
    <>
      {options.staticLoading?<StaticLoadingWidget/>:''}
      <Header label='Active Trials' diseasesName='' quickNavItem='Homepage Trials' mainNav='homepage' />
          <div className='card p-3'>
            <div className="row mb-1">
              <div className="col">
                <h3>Active Trials</h3>
              </div>
              <div className="col">
                {<span class="float-end">
                  <ButtonGroup className="mb-1" aria-label="outlined primary button group">
                    <Button onClick={() => handleView(false)} className={`btn-group ${!dataview && 'btn-active'}`} title="Title">
                      <ListAltIcon/>
                    </Button>
                    <Button onClick={() => handleView(true)}  className={`btn-group ${dataview && 'btn-active'}`} title="Accordion">
                      <AllInboxIcon/>
                    </Button>
                  </ButtonGroup>
                </span>}
              </div>
            </div>
            <div class="row mb-3">
            {dataview&&<div class="col-4">
                <Select
                  className="select-bar-global mt-2"
                  style={{ width: '100%' }}
                  value={selection}
                  id="size-small-standard"
                  size="small"
                  variant="outlined"
                  onChange={handleSelect}
                  inputProps={{
                    name: 'selection',
                    id: 'selection',
                  }}
                >
                  <MenuItem value={'axl_company'}>Company</MenuItem>
                  <MenuItem value={'axl_primary_intervention'}>Intervention</MenuItem>
                  <MenuItem value={'condition'}>Condition</MenuItem>
                </Select>
              </div>}
              <div className={dataview?'col-8':'col-12'}>
                <AccordionSearchBar searchVal={searchVal} findTrials={findTrials} />
              </div>
            </div>
            {!dataview&&<div class="row mb-3 mt-3">
              <div className='col-4'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filterCategoryOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlCategory || ""}
                  onChange={(e, value) =>
                    setAxlCategory(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Intervention Category"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filterClassOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlClass || ""}
                  onChange={(e, value) =>
                    setAxlClass(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Class"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filtersetAxlPrimaryInterventionOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlPrimaryIntervention || ""}
                  onChange={(e, value) =>
                    setAxlPrimaryIntervention(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Primary Intervention"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className='col-4 mt-3'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filterPhaseOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlPhase || ""}
                  onChange={(e, value) =>
                    setAxlPhase(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Phase"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className='col-4 mt-3'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filterStatusOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlStatus || ""}
                  onChange={(e, value) =>
                    setAxlStatus(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Status"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
              <div className='col-4 mt-3'>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  className="filter-autocomplete"
                  options={filterCompanyOptions || []}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  value={axlCompany || ""}
                  onChange={(e, value) =>
                    setAxlCompany(value?value:'')
                  }
                  renderInput={(params) => (
                    <TextField variant="outlined"
                      label="Select Company"
                      placeholder={"Search..."}
                      // onChange={handleSearchCEOs}
                      {...params}
                    />
                  )}
                />
              </div>
            </div>}
            {selection=='axl_company' &&
            <div className="row">
              <div className="col-12">
                <AccordionCheckFilters checkBoxs={checkBoxs} updateCheckBoxs={updateCheckBoxs}/>
              </div>
            </div>}
            {trials.loading ? (
              <div>
                <LoadingWidget />
              </div>
            ) : (
            <>
              {dataview? selection=='condition' || selection=='axl_company'?<Conditions apiCallRequest={apiCallRequest1} options={options} loadMoreRequest={loadMoreRequest} />:<ActiveTrialsGrid apiCallRequest={apiCallRequest} options={options} loadMoreRequest={loadMoreRequest} />
              :<span class='trials-table'><GeneralTable financials={dataArrangement} options={options} apiCallRequest={apiCallRequestTable} />
              <div class="col-12">
                  {getCount >= limit && <div className='d-flex justify-content-center load-more-btn'>
                    <Button onClick={loadMoreRequest} style={{
                      border : '1px solid',
                      borderRadius : '3px',
                      padding: '2px 8px',
                      margin: '5px 0px',
                      color: 'black',
                      fontSize:'12px'
                    }}> Load More</Button>
                </div>}
              </div></span>}
              {/* <ActiveTrialsModal /> */}
            </>
            )}
          </div>
    </>
  );
};

export default ActiveTrials;
