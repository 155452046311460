export const listOfDiseases = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const listOfFeaturedDiseases = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const listOfConditions = `${process.env.REACT_APP_SERVER_URL}/api/condition/type/listname`;
export const listOfSpecializations = `${process.env.REACT_APP_SERVER_URL}/api/condition/type/specialization`;
export const listOfStatCount = `${process.env.REACT_APP_SERVER_URL}/api/condition/type/countlist`;
export const information = `${process.env.REACT_APP_SERVER_URL}/api/condition/1`;
export const organization = `${process.env.REACT_APP_SERVER_URL}/api/organization`;
export const addUser = `${process.env.REACT_APP_SERVER_URL}/api/account/admin_user_register`;
export const getUserGroups = `${process.env.REACT_APP_SERVER_URL}/api/user_group`;
export const getProfile = `${process.env.REACT_APP_SERVER_URL}/api/account/profile/`;
export const getUserPermission = `${process.env.REACT_APP_SERVER_URL}/api/user_permissions`;
export const updateUserPermission = `${process.env.REACT_APP_SERVER_URL}/api/user_permissions/bulk`;
export const diseasesCategoryInfo = `${process.env.REACT_APP_SERVER_URL}/api/report`;
export const getReport = `${process.env.REACT_APP_SERVER_URL}/api/report`;
export const getCondition = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const getReferences = `${process.env.REACT_APP_SERVER_URL}/api/references`;
export const getReference = `${process.env.REACT_APP_SERVER_URL}/api/reference`;
export const getSources = `${process.env.REACT_APP_SERVER_URL}/api/source/condition`;
export const getSource = `${process.env.REACT_APP_SERVER_URL}/api/source`;
export const deleteSourceURL = `${process.env.REACT_APP_SERVER_URL}/api/source`;
export const editorReportPermission = `${process.env.REACT_APP_SERVER_URL}/api/report_permission`;
export const deleteReport = `${process.env.REACT_APP_SERVER_URL}/api/report`;
export const deleteReportPermission = `${process.env.REACT_APP_SERVER_URL}/api/report_permission`;
export const deleteDbPermission = `${process.env.REACT_APP_SERVER_URL}/api/trial_permission`;
export const deleteUser = `${process.env.REACT_APP_SERVER_URL}/api/user`;
export const getUser = `${process.env.REACT_APP_SERVER_URL}/api/user`;
export const getDBperModal = `${process.env.REACT_APP_SERVER_URL}/api/trial_models_codenames/models`;
export const getDBCodeModal = `${process.env.REACT_APP_SERVER_URL}/api/trial_models_codenames`;
export const addpermission = `${process.env.REACT_APP_SERVER_URL}/api/trial_permission`;
export const deleteDBrecord = `${process.env.REACT_APP_SERVER_URL}/api/trial_permission`;
export const getImage = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const deleteImage = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const getDiseaseDashboardCardById = `${process.env.REACT_APP_SERVER_URL}/api/card_list`;
export const bulkCard = `${process.env.REACT_APP_SERVER_URL}/api/card_bulk_lists`;
export const cardById = `${process.env.REACT_APP_SERVER_URL}/api/cards`;
export const sectionUpdate = `${process.env.REACT_APP_SERVER_URL}/api/card_lists`;
export const addField = `${process.env.REACT_APP_SERVER_URL}/api/card_list_datas`;
export const addSource = `${process.env.REACT_APP_SERVER_URL}/api/source`;
export const searchSource = `${process.env.REACT_APP_SERVER_URL}/api/sources`;
export const updateSource = `${process.env.REACT_APP_SERVER_URL}/api/source`;
export const getQuestions = `${process.env.REACT_APP_SERVER_URL}/api/questions`;
export const getFilters = `${process.env.REACT_APP_SERVER_URL}/api/questions/filters`;
export const getTrialsData = `${process.env.REACT_APP_SERVER_URL}/api/trials/condition`;
export const getTrialsDataByCategory = `${process.env.REACT_APP_SERVER_URL}/api/trials/category`;
export const getWaitlist = `${process.env.REACT_APP_SERVER_URL}/api/waitlist`;
export const allDiseasesAlphabetically = `${process.env.REACT_APP_SERVER_URL}/api/condition/type/listdetails`;
export const allConditionsList = `${process.env.REACT_APP_SERVER_URL}/api/condition/type/list`;
export const searchConditions = `${process.env.REACT_APP_SERVER_URL}/api/condition`;
export const glaucomaTrials = `${process.env.REACT_APP_SERVER_URL}/api/trials/condition/128/list`;
export const filteredTrials = `${process.env.REACT_APP_SERVER_URL}/api/trials/condition/128/list`;
export const getEfficacyTable = `${process.env.REACT_APP_SERVER_URL}/api/efficacy/condition`;
export const getEfficacyTableByCategory = `${process.env.REACT_APP_SERVER_URL}/api/axl_generic/category`;
export const getTreatmentsByCondition = `${process.env.REACT_APP_SERVER_URL}/api/treatment/condition`;
export const getTreatmentsByCategory = `${process.env.REACT_APP_SERVER_URL}/api/treatment/category`;
export const getTreatments = `${process.env.REACT_APP_SERVER_URL}/api/treatment`;
export const getPeopleByCondition = `${process.env.REACT_APP_SERVER_URL}/api/people/condition`;
export const getPeople = `${process.env.REACT_APP_SERVER_URL}/api/people`;
export const getPlayersByCondition = `${process.env.REACT_APP_SERVER_URL}/api/players/condition`;
export const getPhysiciansByCompany = `${process.env.REACT_APP_SERVER_URL}/api/open_payments`;
export const getModality = `${process.env.REACT_APP_SERVER_URL}/api/modality`;
export const getModalityItems = `${process.env.REACT_APP_SERVER_URL}/api/modality_items`;
export const bookmarkDiseases = `${process.env.REACT_APP_SERVER_URL}/api/bookmark`;
export const getPlayers = `${process.env.REACT_APP_SERVER_URL}/api/players`;
export const getPublicCompanies = `${process.env.REACT_APP_SERVER_URL}/api/public_companies`;
export const getCompanyPipeline = `${process.env.REACT_APP_SERVER_URL}/api/pipeline`;
export const getCompany = `${process.env.REACT_APP_SERVER_URL}/api/companies`;
export const getDeals = `${process.env.REACT_APP_SERVER_URL}/api/deals`;
export const getTechnology = `${process.env.REACT_APP_SERVER_URL}/api/technology`;
export const getTarget = `${process.env.REACT_APP_SERVER_URL}/api/axl_target`;
export const getUserSettings = `${process.env.REACT_APP_SERVER_URL}/api/user_settings`;
export const getUserSettingsItems = `${process.env.REACT_APP_SERVER_URL}/api/user_settings_items`;
export const getSelectedPapers = `${process.env.REACT_APP_SERVER_URL}/api/selected_papers`;
export const getPhysicians = `${process.env.REACT_APP_SERVER_URL}/api/physicians`;
export const getBrands = `${process.env.REACT_APP_SERVER_URL}/api/brands`;
export const getOrangebookProducts = `${process.env.REACT_APP_SERVER_URL}/api/orangebook/products`;
export const getDrugPricing = `${process.env.REACT_APP_SERVER_URL}/api/drug_pricing`;
export const getVentureCapital = `${process.env.REACT_APP_SERVER_URL}/api/venture_capital`;
export const getVentureCapitalDeals = `${process.env.REACT_APP_SERVER_URL}/api/venture_capitals/deals`;
export const getUserModules = `${process.env.REACT_APP_SERVER_URL}/api/axl_user_modules`;
export const getUserModulesPermission = `${process.env.REACT_APP_SERVER_URL}/api/axl_user_modules_permission`;
export const getDevicePma = `${process.env.REACT_APP_SERVER_URL}/api/axl_device_pma`;
export const getDevice510k = `${process.env.REACT_APP_SERVER_URL}/api/axl_device_510k`;
export const getGeneric = `${process.env.REACT_APP_SERVER_URL}/api/axl_generic`;
export const getProducts = `${process.env.REACT_APP_SERVER_URL}/api/axl_product`;
export const clearCacheUrl = `${process.env.REACT_APP_SERVER_URL}/api/clear_cache`;
export const getPurpleBook = `${process.env.REACT_APP_SERVER_URL}/api/axl_purplebook`;
