import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandableRow from './reusableComponents/Accordion/ExpandableRow';
import { numberWithCommas } from './Helper';
import './ActiveTrialsPhases.scss';
import { Button } from '@material-ui/core';

const CompanyDealsGrid = ({options, apiCallRequest, loadMoreRequest}) => {
  const dealsStore = useSelector((state) => state.diseases);
  const { dataArrangement, playersDataLevelLeaf } = dealsStore;
  let deals = dataArrangement;
  let totalDeals = 0//countArrayLengths(deals, 2);

  if(playersDataLevelLeaf['data']){
    options.leafModalData = {
      name:playersDataLevelLeaf['data']['target'],
      nextData:playersDataLevelLeaf['data']
    }
  }

  return (
    <div className='expandable-table'>
      <div className='expandable-header-row'
      >
        <div className='expandable-cell'>
          Company | Target
        </div>
        <div className='expandable-cell text-capitalize' style={{textAlign:'left'}}>
          Last Deal
        </div>
        <div className='expandable-cell text-capitalize'>
          Category
        </div>
        <div className='expandable-cell text-capitalize'>
          #
        </div>
      </div>

      {deals.map((item, index) => {
        totalDeals += item['total']
        return item['name'] && <ExpandableRow apiCallRequest={apiCallRequest} item={item} options={options} />;
      })}
      {options.dataCount > options.offset_limit && <div className='d-flex justify-content-center load-more-btn'><Button onClick={loadMoreRequest} style={{
        border : '1px solid #2bb670',
        borderRadius : '3px',
        padding: '2px 8px',
        margin: '5px 0px',
        color: '#ffffff',
        fontSize:'12px',
        backgroundColor:'#2bb670'
      }}>Load More</Button></div>}
      <div className='expandable-footer-row'>
        <div className='expandable-cell'>
          Total
        </div>
        <div className='expandable-cell'></div>
        <div className='expandable-cell'></div>
        <div
          className='expandable-cell'
          style={{textAlign: 'right'}}
        >
          {numberWithCommas(totalDeals)}
        </div>
      </div>
    </div>
  );
};

export default CompanyDealsGrid;
