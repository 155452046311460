import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, splitText, numberWithCommas, amountToAbbreviateNumber, convertToHumanDate } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AxlChips from './reusableComponents/MUI/AxlChips';
import linkedin from '../../assets/img/linkedin.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
      fontSize: '2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function CompanyDealsModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-12">
                        <table class="table table-bordered modal-table modal-table-deals modal-table-ceo text-capitalize">
                            {item['vc']['axl_company_legalname'] && <tr>
                              <th class='font-weight-bold'>Legal Name</th>
                              <th>{item['vc']['axl_company_legalname']}
                                {item['vc']['axl_company_linkedin'] && <Link
                                  style={{color:'#6b6767'}}
                                  target="_blank"
                                  title="Linkedin"
                                  to={{
                                    pathname: item['vc']['axl_company_linkedin'],
                                  }}>
                                    <img src={linkedin} width="20px" />
                                </Link>}
                                {item['vc']['axl_company_url'] && <Link
                                  style={{color:'#6b6767'}}
                                  target="_blank"
                                  title="Website URL"
                                  to={{
                                    pathname: item['vc']['axl_company_url'],
                                  }}>
                                    <LanguageIcon/>
                                </Link>}
                              </th>
                            </tr>}
                            {item['vc']['axl_company_type']&&<tr>
                              <th class='font-weight-bold'>Type</th>
                              <th>
                                {/* {item['vc']['axl_company_type']} */}
                                <div className="chips-container p-0 m-0">
                                    <AxlChips options={{chips: item['vc']['axl_company_type'], size: 'small'}} />
                                </div>
                              </th>
                            </tr>}
                            {item['vc']['company_table_status'] &&<tr>
                              <th class='font-weight-bold'>Status</th>
                              <th>
                                {/* {item['vc']['axl_company_status']} */}
                                <div className="chips-container p-0 m-0">
                                    <AxlChips options={{chips: item['vc']['company_table_status'], size: 'small'}} />
                                </div>
                              </th>
                            </tr>}
                            {item['vc']['axl_company_type'] &&<tr>
                              <th class='font-weight-bold'>Company Type</th>
                              <th>
                                {/* {item['vc']['axl_company_type']} */}
                                <div className="chips-container p-0 m-0">
                                    <AxlChips options={{chips: item['vc']['axl_company_type'], size: 'small'}} />
                                </div>
                              </th>
                            </tr>}
                            {item['vc']['axl_company_vc_ceo'] &&<tr>
                              <th class='font-weight-bold'>CEO</th>
                              <th>
                                {item['vc']['ceo_linkedin'] ?
                                  <>
                                    <Link
                                      style={{color:'black',padding:0, textDecoration:'underline'}}
                                      target="_blank"
                                      title="Linkedin"
                                      to={{
                                        pathname: '/CEOs?search=' + item['vc']['axl_company_vc_ceo'],
                                      }}>
                                        {item['vc']['axl_company_vc_ceo']}
                                    </Link>
                                    <Link
                                      style={{color:'#6b6767'}}
                                      target="_blank"
                                      title="Linkedin"
                                      to={{
                                        pathname: item['vc']['ceo_linkedin'],
                                      }}>
                                        <img src={linkedin} width="20px" />
                                    </Link>
                                  </> : item['vc']['axl_company_vc_ceo']
                                }
                              </th>
                            </tr>}
                            {item['vc']['axl_company_vc_funding_date'] &&<tr>
                              <th class='font-weight-bold'>Funding Date</th>
                              <th>{convertToHumanDate(item['vc']['axl_company_vc_funding_date'])}</th>
                            </tr>}
                            {item['vc']['axl_company_vc_funding_round'] &&<tr>
                              <th class='font-weight-bold'>Funding Round</th>
                              <th>
                                {/* {item['vc']['axl_company_vc_funding_round']} */}
                                <div className="chips-container p-0 m-0">
                                    <AxlChips options={{chips: item['vc']['axl_company_vc_funding_round'], size: 'small'}} />
                                </div>
                              </th>
                            </tr>}
                            {item['vc']['axl_company_vc_fundingraised'] &&<tr>
                              <th class='font-weight-bold'>Funding Raised</th>
                              <th>{amountToAbbreviateNumber(item['vc']['axl_company_vc_fundingraised'])}</th>
                            </tr>}
                            {item['vc']['axl_company_vc_totalfundingraised'] &&<tr>
                              <th class='font-weight-bold'>Total Funding Raised</th>
                              <th>{amountToAbbreviateNumber(item['vc']['axl_company_vc_totalfundingraised'])}</th>
                            </tr>}
                            {item['vc']['axl_condition'] &&<tr>
                              <th class='font-weight-bold'>Condition</th>
                              <th>
                                {splitText(item['vc']['axl_condition'],';',100)}
                              </th>
                            </tr>}
                            {item['vc']['axl_intervention_brand'] &&<tr>
                              <th class='font-weight-bold'>Brand</th>
                              <th>{item['vc']['axl_intervention_brand']}</th>
                            </tr>}
                            {item['vc']['axl_intervention_class'] &&<tr>
                              <th class='font-weight-bold'>Class</th>
                              <th>{item['vc']['axl_intervention_class']}</th>
                            </tr>}
                            {item['vc']['axl_intervention_generic_name'] &&<tr>
                              <th class='font-weight-bold'>Generic Name</th>
                              <th>{item['vc']['axl_intervention_generic_name']}</th>
                            </tr>}
                            {item['vc']['axl_intervention_modality'] &&<tr>
                              <th class='font-weight-bold'>Modality</th>
                              <th>{item['vc']['axl_intervention_modality']}</th>
                            </tr>}
                            {item['vc']['axl_target_fundraising_rationale_extracted'] &&<tr>
                              <th class='font-weight-bold'>Rationale</th>
                              <th>{item['vc']['axl_target_fundraising_rationale_extracted']}</th>
                            </tr>}
                            {item['vc']['axl_target_fundraising_technologyorclass_extracted'] &&<tr>
                              <th class='font-weight-bold'>Technology or Class</th>
                              <th>{item['vc']['axl_target_fundraising_technologyorclass_extracted']}</th>
                            </tr>}
                            {item['vc']['axl_vc_allinvestors'] &&<tr>
                              <th class='font-weight-bold'>All Investors</th>
                              <th>
                                {/* {splitText(item['vc']['axl_vc_allinvestors'],';',100)} */}
                                <div className="chips-container p-0 m-0">
                                    <AxlChips options={{chips: item['vc']['axl_vc_allinvestors'], size: 'small'}} />
                                </div>
                              </th>
                            </tr>}
                            {item['vc']['axl_vc_condition_extracted'] &&<tr>
                              <th class='font-weight-bold'>VC Condition</th>
                              <th>
                                {splitText(item['vc']['axl_vc_condition_extracted'],';',100)}
                              </th>
                            </tr>}
                            {item['vc']['axl_vc_fundraising_indication_extracted'] &&<tr>
                              <th class='font-weight-bold'>Raising Indication</th>
                              <th>
                                {splitText(item['vc']['axl_vc_fundraising_indication_extracted'],';',100)}
                              </th>
                            </tr>}
                            {item['vc']['suggested_intervention_class'] &&<tr>
                              <th class='font-weight-bold'>Intervention Class</th>
                              <th>{item['vc']['suggested_intervention_class']}</th>
                            </tr>}
                        </table>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default CompanyDealsModal;