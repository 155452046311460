import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, numberWithCommas, splitText } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AxlChips from './reusableComponents/MUI/AxlChips';
import linkedin from '../../assets/img/linkedin.png';
import Investments from './Investments';
import DynamicExits from './DynamicPage/DynamicExits';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
      fontSize: '2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function VentureCapitalModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  let vc_investment_options = {
    'active': true,
    'company_status':'VCmodal',
    'vc_id':item?item.venture_capital.id:''
  };
  // console.log(item)
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item.name}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container modal-data">
                    <div className="row pt-3">
                      <div className="col-8">
                          <>
                            <h1 className="me-3">
                              <span class="me-2">
                                {item.name}
                              </span>
                              <br></br>
                              <span class="h5">
                                {item.venture_capital.axl_vc_title}
                              </span>
                            </h1>
                            {item.venture_capital.axl_vc_location &&
                            <span>
                              {item.venture_capital.axl_vc_location}<br/>
                            </span>
                            }
                            <span>
                              {item.venture_capital.axl_linkedin_page &&
                                <Link
                                target="_blank"
                                title="Linkedin"
                                class="me-2"
                                style={{color:'#6b6767'}}
                                to={{
                                  pathname: item.venture_capital.axl_linkedin_page,
                                }}>
                                  <img src={linkedin} width="20px" />
                                </Link>
                              }
                              {item.venture_capital.axl_bio_page &&
                                <Link
                                target="_blank"
                                title="Website"
                                style={{color:'#6b6767'}}
                                to={{
                                  pathname: item.venture_capital.axl_bio_page,
                                }}>
                                  <LanguageIcon/>
                                </Link>
                              }
                            </span>
                          </>
                      </div>
                      <div className="col-4">
                        {item.venture_capital.axl_vc_firm &&
                            <h2 className="me-3">
                              <span class="me-2">
                                {splitText(item.venture_capital.axl_vc_firm,';',100)}
                              </span>
                            </h2>
                        } 
                      </div>
                    </div>
                    <div className="row pt-3">
                        {item.venture_capital.axl_vc_bio && 
                        <div className="col-12 mb-3">
                          <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                            <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Bio</h4>
                          </div>
                          <p class="ms-3">{item.venture_capital.axl_vc_bio}</p>
                        </div>}
                        {(item.venture_capital.axl_phd_university || item.venture_capital.axl_md_university || item.venture_capital.axl_mba_university || item.venture_capital.axl_undergrad_university) &&
                        <div className="col-12 mb-3">
                          <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                            <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Education</h4>
                          </div>
                          <p class="ms-3">
                            {item.venture_capital.axl_phd_university && 
                              <span>
                                {item.venture_capital.axl_phd_university} <br></br>
                              </span>
                            }
                            {item.venture_capital.axl_md_university && 
                              <span>
                                {item.venture_capital.axl_md_university} <br></br>
                              </span>
                            }
                            {item.venture_capital.axl_mba_university && 
                              <span>
                                {item.venture_capital.axl_mba_university} <br></br>
                              </span>
                            }
                            {item.venture_capital.axl_undergrad_university && 
                              <span>
                                {item.venture_capital.axl_undergrad_university} <br></br>
                              </span>
                            }
                          </p>
                        </div>}
                        {item.venture_capital.company_type.length > 0 &&
                          <div className="col-12 mb-3">
                            <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                              <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Selected Investments</h4>
                            </div>
                            <Investments hideHeader={true} search_vc='' vc_investment_options={vc_investment_options} />
                            {/* <p class="ms-3"> */}
                              {/* {splitText(item.venture_capital.axl_vc_boards,';',200)} */}
                              {/* <div className="chips-container">
                                  <AxlChips options={{chips: item.venture_capital.axl_vc_boards, size: 'small'}} />
                              </div>
                            </p> */}
                          </div>
                        }
                        {item.venture_capital.id &&
                          <div className="col-12 mb-3">
                            <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                              <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Exits</h4>
                            </div>
                            <DynamicExits hideHeader={true} search_vc='' vc_exits_options={vc_investment_options} />
                            {/* <p class="ms-3"> */}
                              {/* {splitText(item.venture_capital.axl_vc_boards,';',200)} */}
                              {/* <div className="chips-container">
                                  <AxlChips options={{chips: item.venture_capital.axl_vc_boards, size: 'small'}} />
                              </div>
                            </p> */}
                          </div>
                        }
                        
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default VentureCapitalModal;