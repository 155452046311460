import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver'

export function exportToPNG(htmlId, fileName = "exportToPNG") {
    const elementToExport = document.getElementById(htmlId);
    if(elementToExport){
        toBlob(elementToExport)
        .then(function (blob) {
            if (window.saveAs) {
                window.saveAs(blob, fileName+'.png');
            } else {
            saveAs(blob, fileName+'.png');
            }
        });
    } else {
        console.log("HTML ID not found! Cannot export to PNG.");
    }
}

export function countArrayLengths(obj, no_of_keys = 1) {
  const result = {
    totalLength: 0
  };

  function countLength(value) {
    if (Array.isArray(value)) {
      result.totalLength += value.length;
    } else if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          countLength(value[key]);
        }
      }
    }
    // Ignore non-array leaf nodes
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      countLength(obj[key]);
    }
  }

  return result.totalLength/no_of_keys;
}

// search object function that takes object and search string as input and returns the object with the search string matching keys or any values
export function searchObject(obj, searchTerm) {
  const isMatch = (str) => str && str.toString().toLowerCase().includes(searchTerm.toLowerCase());

  function search(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    const result = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      const value = obj[key];
      const keyMatch = isMatch(key);
      const valueMatch = isMatch(value);

      if (keyMatch || valueMatch) {
        result[key] = value;
      }

      if (typeof value === 'object' && value !== null) {
        result[key] = search(value);
      }
    }
    return result;
  }
  return search(obj);
}

export function numberWithCommas(num) {
  if(num>0){
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }else{
    return '-';
  }
}

export function convertToMimillions(num){
  if(num>0){
    var new_num = num
    if(typeof num === 'string'){
      new_num = Number(num.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ""));
    }
    if(new_num>1000000){
      return Math.round(Number(new_num/1000000))
    }else{
      new_num = Number(new_num/1000000)
      return Math.round((new_num + Number.EPSILON) * 100) / 100
    }
  }else{
    return '-';
  }
}

export function nameToInitials(name){
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
}

export function serialize(obj) {
  var str = [];
  if(obj){
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
      let string = str.join("&");
      let search = ''
      if(Object.keys(obj).length !== 0){
        search = '?'+string
      }
      return search
  }else{
    return '-'
  }
}

export function splitText(text,separator,limit,ellipsis) {
  if(text){
    let parts = text.split(separator).map(part => part.trim());
    let res = parts.slice(0, limit);
    res = res.join(' | ');
    if(ellipsis){
      return res.length > ellipsis?res.substring(0,ellipsis)+'...':res
    }else{
      return res;
    }
  }else{
    return '-'
  }
}

export function removeDayAndMonthFromDate(para) {
  if(para){
    const dateStr = para;
    const date = new Date(dateStr);

    // Get the month and year
    const options = { year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);

    // Output the result as "2023"
    const result = formattedDate.replace(" ", ", ");
    return result
  }else{
    return '-'
  }
}

export function removeDayFromDate(para) {
  if(para){
    const dateStr = para;
    const date = new Date(dateStr);

    // Get the month and year
    const options = { year: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    // Output the result as "May-2023"
    const result = formattedDate.replace(" ", ", ");
    return result
  }else{
    return '-'
  }
}

export function convertToHumanDate(para) {
  if (para) {
    const date = new Date(para);

    // Get the day, month, and year
    const day = date.getDate(); // Gets day without leading zero
    const month = date.toLocaleString('en-GB', { month: 'short' }); // Gets abbreviated month name
    const year = date.getFullYear(); // Gets full year

    // Format the date as '12 Oct, 2024'
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  } else {
    return '-';
  }
}

export function amountToAbbreviateNumber(amount) {
  if(amount){
    let currency_label = '$'
    if(typeof amount == 'string'){
      amount = amount.toLowerCase()
      if (amount.includes("eur")) {
        currency_label = '€'
      }else if (amount.includes("krw")) {
        currency_label = '₩'
      }else if (amount.includes("cad")) {
        currency_label = 'C$'
      }
      amount = amount.replaceAll(',','').replaceAll(' ','').replaceAll('$','').replaceAll('eur','').replaceAll('krw','').replaceAll('cad','')
    }
    amount = Number(amount)
    let con_amt = 0;
    if (amount >= 1e12) {
      con_amt = currency_label + Math.floor(amount / 1e12) + 'T';
    } else if (amount >= 1e9) {
      con_amt = currency_label + Math.floor(amount / 1e9) + 'B';
    } else if (amount >= 1e6) {
      con_amt = currency_label + Math.floor(amount / 1e6) + 'M';
    } else if (amount >= 1e3) {
      con_amt = currency_label + Math.floor(amount / 1e3) + 'K';
    } else {
      con_amt = currency_label + amount.toString();
    }
    if(amount>0){
      return con_amt
    }else{
      return '-'
    }
  }else{
    return '-'
  }
}

export function differenceDays(val1, val2){
  // Define the two dates
  let date1 = new Date(val1);
  let date2 = new Date();
  if(val2){
    date2 = new Date(val2);
  }

  // Calculate the difference in milliseconds
  const differenceInMs = date2 - date1;

  // Convert milliseconds to days
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
  if(val1 && val2){
    if(differenceInDays){
      return Math.round(differenceInDays)+' days'
    }else{
      return '-'
    }
  }else{
    return '-'
  }
}

export function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export function isDateString(value) {
  // Check for YYYY-MM-DD or DD-MMM-YYYY format
  const dateRegex1 = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD
  const dateRegex2 = /^\d{2}-[A-Za-z]{3}-\d{4}$/; // Matches DD-MMM-YYYY

  if(dateRegex1.test(value) || dateRegex2.test(value)){
    return true;
  }else{
    return false;
  }
}

export function exactSearch(search) {
  if (search.startsWith('"') && search.endsWith('"')) {
    search = search.replaceAll('"','')
    return {'search':search, 'search_type':'exact'}
  }else{
    search = search.replaceAll('"','')
    return {'search':search, 'search_type':''}
  }
}


// Convert array to double quoted string
export function arrayToDoubleQuotedString(arr) {
  return arr.map(item => `"${item}"`).join(' ');
}

export function capitalizeFirstLetter(str){
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

// Split text with new line in HTML with <li> tag
export function splitWithNewLineHTML(text, separator) {
  if(text){
    let parts = text.split(separator).map(part => part.trim());
    let res = parts.join('<br>');
    return res;
  }else{
    return ''
  }
}